<template>
  <div>
    <!-- 导航 -->
    <div class="nav-title">
      <van-icon name="arrow-left" @click="$router.go(-1)"/>
      <span :class="{ text: orderType === 1 }" @click="setOrderType(1)">
        普通订单
      </span>
      <span :class="{ text: orderType === 2 }" @click="setOrderType(2)">
        普通订单
      </span>
      <span :class="{ text: orderType === 3 }" @click="setOrderType(3)">
        公益订单
      </span>
      <van-icon name="ellipsis" />
    </div>
    <!-- 订单列表 -->
    <div class="layout-content">
      <OrderCard></OrderCard>
    </div>
  </div>
</template>

<script>
import OrderCard from "@/components/order-card/order-card";
export default {
  name: "after-sale",
  components: {
    OrderCard,
  },
  data() {
    return {
      orderType: 1,
    };
  },
  methods: {
    setOrderType(num) {
      this.orderType = num;
    },
  },
};
</script>

<style lang="less" scoped>
// 导航栏样式
.nav-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  padding: 12px;
  font-size: 20px;
  color: #6f6f6f;

  .text {
    font-weight: 600;
    color: #000;
  }
}
.layout-content{
  padding: 12px;
}
</style>